@font-face {
	font-family:'poppins';
	src:url('../fonts/Poppins-Medium.ttf');
}

@font-face {
	font-family:'dinlight';
	src:url('../fonts/DINLight.ttf');
}

@font-face {
	font-family:'gothic';
	src:url('../fonts/ChampionGothic-Featherweight.ttf');
}

/*------------------------------------*\
    MAIN
\*------------------------------------*/   
/* global box-sizing */
*,
*:after,
*:before { 
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	-webkit-font-smoothing:antialiased;
	font-smoothing:antialiased;
	text-rendering:optimizeLegibility;
}

/* html element 62.5% font-size for REM use */
html {
	font-size:62.5%;
}
body {
	font-family:'Gotham-Medium', sans-serif;	
	color:#000;
	font-size:16px;
	line-height:26px;
	overflow-x: hidden;
	font-weight:500;
}
/* clear */
.clear:before,
.clear:after {
    content:' ';
    display:table;
}
.clear:after { clear:both;}

.clear {*zoom:1; clear:both;}

img {max-width:100%;	vertical-align:bottom;}

a {	color:#000;	text-decoration:none;}

a:hover {text-decoration:none; color:inherit;}

a:focus {outline:0;text-decoration:none;}

a:hover, a:active {	outline:0;}

input:focus, textarea:focus, button:focus {	outline:0;	border:0;}

ul,li,h1,h2,h3,h4 {list-style-type:none;margin:0;padding:0}

p:not(:last-child){margin-bottom:25px;}

p:last-child{margin-bottom:0px;}

#root{
    background-color: #000;
}
.App{
    background-image: url('../images/bg.png');
    background-size: 100% 100%;
    min-height: 100vh;
    max-width: 400px;
    border-top: 1px solid transparent;
    margin: auto;
}

.logo{
    display: block;
    margin: 25% auto 10px auto;
    width: 50%;
}

.swal2-title{
    font-size: 12px !important;
}

.swal2-icon{
    font-size: 10px;
}

.swal2-actions button{
    font-family: "poppins"
}

.rsvpblock{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.submitblock{
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

form{
    width: 100%;
}

.input-gold{
    background-image: url('../images/yellowbox.png');
    background-size: 100% 100%;
    width : 70%;
    height: 50px;
    padding: 10px;
    margin: 0 auto 20px auto;
}
.input-pink{
    background-image: url('../images/pinkbox.png');
    background-size: 100% 100%;
    width : 70%;
    height: 50px;
    padding: 10px;
    margin: auto;
}

input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: center;
    font-family: 'poppins';
    color: #fff;
}

.submit{
    width: 40%;
    display: block;
    margin: 20px auto;
    height: 40px;
    border: none;
    background: transparent;
    background-image: url("../images/button.png");
    background-size: 100% 100%;
    color: #fff;
    font-family: 'poppins';
}

.rsvpheree{
    width: 50%;
    display: block;
    margin: 10px auto;
}

.thanks{
    width: 70%;
    margin-bottom: 30px;
}

.seeyou{
    width: 90%;
}

@media(max-width:575px){
    .App{
        width: 100%;
    }

    .logo{
        display: block;
        margin: 40% auto 10px auto;
        width: 70%;
    }

    
}

